// ** Checks if an object is empty (returns boolean)
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import firebase from '@firebase/app';
import { NotificationManager } from "react-notifications";


export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

const MySwal = withReactContent(Swal)
export const handleConfirmText = (props) => {
  return MySwal.fire({
    title: 'login session has been expired ?',
    text: "login again to continue accessing your account !",
    icon: 'warning',
    showCancelButton: false,
    confirmButtonColor: "#3085d6",
    confirmButtonText: 'Yes, logout it!', //how to add css to this button?
    customClass: {
      btn : 'btn btn-danger',
      confirmButton: "btn btn-success",
      cancelButton: 'btn btn-outline-danger ml-1',
    },
    allowOutsideClick: false,
    buttonsStyling: true,

  }).then(function (result) {
    if (result.value) {
      firebase
        .auth()
        .signOut();
      localStorage.removeItem("userData");
      window.localStorage.clear();
      window.localStorage.clear();
      window.location.href = "/"
    }
  })

}
const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})
Axios.interceptors.request.use(async (config) => {
  await new Promise((resolve) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(() => {
      unsubscribe();
      resolve();
    });
  });
  config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken(false)}`;
  return config;
});
Axios.interceptors.response.use(
  response => response,
  error => {
    if (firebase.auth().currentUser === null || error.response.status === 401) {
      handleConfirmText();
    } else {
      return Promise.reject(error);
    }

  }
);
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

export { Axios, AxiosInstance }


export const uploadImageToS3 = (file, setisLoader, foldername, height, width) => {
  const allowedExtensions = ["png", "jpg", "jpeg", "pdf"];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (!allowedExtensions.includes(fileExtension)) {
    NotificationManager.error(
      "Only JPG, PNG, JPEG , PDF file types are allowed",
      "Error"
    );
    return Promise.resolve(""); // Return a resolved promise to handle the error
  }

  setisLoader(true);
  return new Promise((resolve, reject) => {
    preSignedUploadImageToS3(file, setisLoader, foldername)
      .then((imageData) => {
        setisLoader(false);
        resolve(imageData);
      })
      .catch((error) => {
        setisLoader(false);
        NotificationManager.error("Failed to upload image", "Error");
        reject(error);
      });
  });
};

export const  deleteImageFromS3 = async (e,filepath, setIsLoading) => {
  try {
    const s3UrlPrefix = "https://sra-automation.s3.ap-south-1.amazonaws.com/";
    const filePath = filepath.replace(s3UrlPrefix, "");

    const response = await Axios.post(`/presigned/generate-presigned-url-for-delete`, {
      filePath: filePath
    });

    if (response.data.status === false) {
      return ""
    } else {
      const presignedUrl = response.data.data;
      try {
        const response = await axios.delete(presignedUrl);
        if (response.status === 204) {
          console.log("File deleted successfully");
          return response
        } else {
          console.error("Failed to delete file", response);
          return ""
        }
      } catch (error) {
        console.error("Error deleting file from S3", error);
        return ""
      }
    }
  } catch (error) {
    console.error("Error uploading file or generating presigned URL:", error);
    return ""
  }
};

//Function for capitalize the text .
export const capitalizeText = (text) => {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};



/********************upload video function for form information master**************/
export const UPLOAD_VIDEOS = async (
  file,
  setIsLoading,
  folderName
) => {
  setIsLoading(true);

  if (file.type.includes("video/")) {

      const isVideo = file.type.includes("video/");
      if (isVideo && file.size <= 51000000) {

        return new Promise((resolve, reject) => {
          preSignedUploadImageToS3(file, setIsLoading, folderName)
            .then((imageData) => {
              setIsLoading(false);
              resolve(imageData);
            })
            .catch((error) => {
              setIsLoading(false);
              NotificationManager.error("Failed to upload image", "Error");
              reject(error);
            });
        });
        
        }else {
        NotificationManager.error(
          "Video size needs to be less than or equal to 50mb",
          "File name: " + file.name
        );
      }
  } else {
    NotificationManager.error("Only video file types are allowed", "Error");
  }
  setIsLoading(false)
}


export const uploadPdfImageToS3 = (file, setIsLoading, foldername, height, width) => {
  const allowedExtensions = ["png", "jpg", "jpeg", "pdf"];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (!allowedExtensions.includes(fileExtension)) {
    NotificationManager.error(
      "Only JPG, PNG, JPEG , PDF file types are allowed",
      "Error"
    );
    return Promise.resolve(""); // Return a resolved promise to handle the error
  }
  
  setIsLoading(true);
  return new Promise((resolve, reject) => {
    preSignedUploadImageToS3(file, setIsLoading, foldername)
      .then((imageData) => {
        setIsLoading(false);
        resolve(imageData);
      })
      .catch((error) => {
        setIsLoading(false);
        NotificationManager.error("Failed to upload image", "Error");
        reject(error);
      });
  });
};

// Get presigned url
export const preSignedUploadImageToS3 = async (file, setIsLoader, folderName) => {
  try {
   // Generate a 4-digit random number
   const randomNumber = Math.floor(1000 + Math.random() * 9000);
  
   // Get current timestamp
   const timestamp = Date.now();

    const response = await Axios.post(`/presigned/generate-presigned-url`, {
      filePath: `${folderName}/${timestamp}_${randomNumber}_${file.name}`,
      fileType: file.type
    });

    if (response.data.status === false) {
      NotificationManager.error("Failed to upload file!");
      setIsLoader(false)
      return ""
    } else {
      const presignedUrl = response.data.data;
      const uploadResponse = await axios.put(presignedUrl, file, {
        headers: {
          'Content-Type': file.type
        }
      });

      if (uploadResponse.status === 200) {
        const fileUrl = presignedUrl.split('?')[0]; // Get the URL without query parameters
        // console.log("File uploaded successfully! File URL:", fileUrl);
        setIsLoader(false)
        return fileUrl;
      } else {
        NotificationManager.error("Failed to upload file!");
        setIsLoader(false)
        return ""
      }
    }
  } catch (error) {
    NotificationManager.error("Failed to upload file!");
    setIsLoader(false)
    console.error("Error uploading file or generating presigned URL:", error);
    return ""
  }
};