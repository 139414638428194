const userType = JSON.parse(localStorage.getItem("userData")) ? JSON.parse(localStorage.getItem("userData")).user_type : "";
const permission = JSON.parse(localStorage.getItem("userData")) ? JSON.parse(localStorage.getItem("userData")).authentication : {};
const MenuItems = [
  // {
  //   menu_title: "Dashboard",
  //   path: "/app/dashboard",
  //   icon: "dashboard",
  //   category: "general",
  //   isMenuOpen: false,
  //   menu: "dashboard",
  //   fullPageMenu: false,
  //   desc: true,
  //   content: "",
  //   permission: permission && permission.Dashboard && permission.Dashboard !== undefined ? permission.Dashboard.length > 0 : false,
  //   child_routes: [
  //     {
  //       path: "/app/dashboard/acceptance-department",
  //       menu_title: "Acceptance Department",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission:
  //         permission && permission.Dashboard && permission.Dashboard !== undefined
  //           ? permission && permission.Dashboard && permission.Dashboard.includes("Acceptance_Dashboard") :
  //           true,
  //     },
  //     {
  //       path: "/app/dashboard/engg-department",
  //       menu_title: "Engineer Department",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission:
  //         permission && permission.Dashboard && permission.Dashboard !== undefined
  //           ? permission && permission.Dashboard && permission.Dashboard.includes("Engineer_Dashboard") :
  //           true,
  //     },
  //     {
  //       path: "/app/dashboard/ctso-department",
  //       menu_title: "CTSO Department",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission:
  //         permission && permission.Dashboard && permission.Dashboard !== undefined
  //           ? permission && permission.Dashboard && permission.Dashboard.includes("CTSO_Dashboard") :
  //           true,
  //     },
  //     {
  //       path: "/app/dashboard/deputy-department",
  //       menu_title: "Deputy Collector Department",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission:
  //         permission && permission.Dashboard && permission.Dashboard !== undefined
  //           ? permission && permission.Dashboard && permission.Dashboard.includes("Deputy_Collector_Dashboard") :
  //           true,
  //     },
  //     // {
  //     //   path: "/app/dashboard/finance-department",
  //     //   menu_title: "Finance Department",
  //     //   icon: "equalizer",
  //     //   fullPageMenu: false,
  //     //   isMenuOpen: false,
  //     //   third_child_routes: null,
  //     //   permission:
  //     //     // permission && permission.Master && permission.Master !== undefined
  //     //     //   ? permission && permission.Master && permission.Master.includes("Form_information_master") :
  //     //     true,
  //     // },
  //     {
  //       path: "/app/dashboard/co-operative-department",
  //       menu_title: "Co-Operative Department",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission:
  //         permission && permission.Dashboard && permission.Dashboard !== undefined
  //           ? permission && permission.Dashboard && permission.Dashboard.includes("Co_Operative_Dashboard") :
  //           true,
  //     },
  //     {
  //       path: "/app/dashboard/town-planning-department",
  //       menu_title: "Town Planning Department",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission:
  //         permission && permission.Dashboard && permission.Dashboard !== undefined
  //           ? permission && permission.Dashboard && permission.Dashboard.includes("Town_Planning_Dashboard") :
  //           true,
  //     },
  //     {
  //       path: "/app/dashboard/legal-department",
  //       menu_title: "Legal Department",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission:
  //         permission && permission.Dashboard && permission.Dashboard !== undefined
  //           ? permission && permission.Dashboard && permission.Dashboard.includes("Legal_Dashboard") :
  //           true,
  //     },
  //     {
  //       path: "/app/dashboard/estate-department",
  //       menu_title: "Estate Department",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission:
  //         permission && permission.Dashboard && permission.Dashboard !== undefined
  //           ? permission && permission.Dashboard && permission.Dashboard.includes("Estate_Dashboard") :
  //           true,
  //     },
  //     {
  //       path: "/app/dashboard/paid-rehab-department",
  //       menu_title: "Paid Rehabilitation Department",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission:
  //         permission && permission.Dashboard && permission.Dashboard !== undefined
  //           ? permission && permission.Dashboard && permission.Dashboard.includes("Paid_Rehabilitation_Dashboard") :
  //           false
  //     },
  //     {
  //       path: "/app/dashboard/ca-department",
  //       menu_title: "Compenent Authority Department",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission:
  //         permission && permission.Dashboard && permission.Dashboard !== undefined
  //           ? permission && permission.Dashboard && permission.Dashboard.includes("Competent_Authority_Dashboard") :
  //           false
  //     },
  //   ]
  // },
  {
    menu_title: "Master",
    path: "/app/master",
    icon: <i className="fa fa-cube" aria-hidden="true"></i>,
    category: "modules",
    isMenuOpen: false,
    menu: "master",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission: permission?.Master !== undefined ? permission.Master.length > 0 : false,
    child_routes: [
      {
        path: "/app/master/user",
        menu_title: "Users",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: permission && permission?.Master && permission?.Master !== undefined ? permission && permission?.Master && permission?.Master?.includes("User_Master") : false,
      },
      {
        path: "/app/master/formInformation",
        menu_title: "Form-Information",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master && permission.Master !== undefined
            ? permission && permission.Master && permission.Master.includes("Form_information_master")
            : false,
      },
      {
        path: "/app/master/designation",
        menu_title: "Designation",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Designation_master") : false,
      },
      {
        path: "/app/master/department",
        menu_title: "Department",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Department_master") && userType === "SuperAdmin"
            : false,
      },
      {
        path: "/app/master/area",
        menu_title: "Area",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Area_master")
            : false,
      },
      {
        path: "/app/master/ward",
        menu_title: "Ward",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Ward_master")
            : false,
      },
      // {
      //   path: "/app/master/scheme-type",
      //   menu_title: "Scheme Type",
      //   icon: "equalizer",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission:
      //     permission && permission.Master !== undefined
      //       ? permission && permission.Master.includes("Scheme_Type_master")
      //       : false,
      // },
      // {
      //   path: "/app/master/architect",
      //   menu_title: "Architect",
      //   icon: "equalizer",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission:
      //     permission && permission.Master !== undefined
      //       ? permission && permission.Master.includes("Architect_master")
      //       : false,
      // },
      // {
      //   path: "/app/master/developer",
      //   menu_title: "Developer",
      //   icon: "equalizer",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission:
      //     permission && permission.Master !== undefined
      //       ? permission && permission.Master.includes("Developer_master")
      //       : false,
      // },
      // {
      //   path: "/app/master/land-type",
      //   menu_title: "Land Type",
      //   icon: "equalizer",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission:
      //     permission && permission.Master !== undefined
      //       ? permission && permission.Master.includes("Land_Type_master")
      //       : false,
      // },
      // {
      //   path: "/app/master/annexure",
      //   menu_title: "Annexure",
      //   icon: "equalizer",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission:
      //     permission && permission.Master !== undefined
      //       ? permission && permission.Master.includes("Annexure_master") && userType === "SuperAdmin"
      //       : false,
      // },
      // {
      //   path: "/app/master/slum-act",
      //   menu_title: "Slum Of Act",
      //   icon: "equalizer",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission:
      //     permission && permission.Master !== undefined
      //       ? permission && permission.Master.includes("Slum_Act_Master")
      //       : false,
      // },
      // {
      //   path: "/app/master/advocate-court",
      //   menu_title: "Advocate Master",
      //   icon: "equalizer",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission:
      //     permission && permission.Master !== undefined
      //       ? permission && permission.Master.includes("Advocate_Master")
      //       : false,
      // },
    ],
  },
  // {
  //   menu_title: "Create Scheme",
  //   path: "/app/create-scheme",
  //   icon: "note_alt",
  //   category: "general",
  //   isMenuOpen: false,
  //   menu: "create-scheme",
  //   fullPageMenu: false,
  //   desc: true,
  //   content: "",
  //   child_routes: null,
  //   permission:
  //     permission && permission.CreateScheme !== undefined
  //       ? permission && permission.CreateScheme.includes("Create_Scheme")
  //       : false,
  // },
  // {
  //   menu_title: "Schemes",
  //   path: "/app/schemes",
  //   icon: "dashboard",
  //   category: "general",
  //   isMenuOpen: false,
  //   menu: "schemes",
  //   fullPageMenu: false,
  //   desc: true,
  //   content: "",
  //   child_routes: null,
  //   permission:
  //     permission && permission.SchemeList !== undefined
  //       ? permission && permission.SchemeList.includes("Scheme_List")
  //       : false,
  // },
  // {
  //   menu_title: "Legal Department",
  //   path: "/app/create-case",
  //   icon: "account_balance",
  //   category: "general",
  //   isMenuOpen: false,
  //   menu: "create-case",
  //   fullPageMenu: false,
  //   desc: true,
  //   content: "",
  //   child_routes: null,
  //   permission:
  //     permission && permission.Department !== undefined
  //       ? permission && permission.Department.includes("Legal_Department")
  //       : false,
  // },


  // {
  //   menu_title: "Deputy Collector Department",
  //   path: "/app/collector",
  //   // icon: <i className="fa fa-cube" aria-hidden="true"></i>,
  //   icon: <i className="fa fa-network-wired" aria-hidden="true" ></i>,
  //   category: "modules",
  //   isMenuOpen: false,
  //   menu: "deputy-dept",
  //   fullPageMenu: false,
  //   desc: true,
  //   content: "",
  //   permission: permission && permission?.Deputy_Collector_Department !== undefined
  //     ? permission && permission?.Deputy_Collector_Department !== undefined
  //     : false,
  //   child_routes: [
  //     {
  //       path: "/app/collector/legal-heir",
  //       menu_title: "Legal Heir (Varas)",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission: permission && permission?.Deputy_Collector_Department && permission?.Deputy_Collector_Department !== undefined ? permission && permission?.Deputy_Collector_Department && permission?.Deputy_Collector_Department?.includes("Legal_Heir_varas") : false,
  //     },
  //     {
  //       path: "/app/collector/register-case",
  //       menu_title: "Register Of Section 13(2) Case",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission: permission && permission?.Deputy_Collector_Department && permission?.Deputy_Collector_Department !== undefined ? permission && permission?.Deputy_Collector_Department && permission?.Deputy_Collector_Department?.includes("Register_of_section_13_2_case") : false,

  //     },

  //     {
  //       path: "/app/collector/form-14-part-1",
  //       menu_title: "Form 14 (1) Part(I)",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission: permission && permission?.Deputy_Collector_Department && permission?.Deputy_Collector_Department !== undefined ? permission && permission?.Deputy_Collector_Department && permission?.Deputy_Collector_Department?.includes("Form_14_Part_1") : false,

  //     },
  //     {
  //       path: "/app/collector/form-14-part-2",
  //       menu_title: "Form 14 (1) Part(II)",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission: permission && permission?.Deputy_Collector_Department && permission?.Deputy_Collector_Department !== undefined ? permission && permission?.Deputy_Collector_Department && permission?.Deputy_Collector_Department?.includes("Form_14_Part_2") : false,
  //     },
  //     {
  //       path: "/app/collector/register-annexure-2",
  //       menu_title: "Register Of Annexure 2",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission: permission && permission?.Deputy_Collector_Department && permission?.Deputy_Collector_Department !== undefined ? permission && permission?.Deputy_Collector_Department && permission?.Deputy_Collector_Department?.includes("Register_Of_Annexure_2") : false,
  //     },
  //     {
  //       path: "/app/collector/register-section-33-38",
  //       menu_title: "Register Of Section 33 Or 38 Case",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission: permission && permission?.Deputy_Collector_Department && permission?.Deputy_Collector_Department !== undefined ? permission && permission?.Deputy_Collector_Department && permission?.Deputy_Collector_Department?.includes("Register_of_Section_33_Or_38") : false,

  //     },
  //     {
  //       path: "/app/collector/register-of-agreement",
  //       menu_title: "Register Of Agreement",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission: permission && permission?.Deputy_Collector_Department && permission?.Deputy_Collector_Department !== undefined ? permission && permission?.Deputy_Collector_Department && permission?.Deputy_Collector_Department?.includes("Register_of_Agreement") : false,

  //     },
  //     // {
  //     //   path: "/app/collector/paid-rehabilitation",
  //     //   menu_title: "Paid Rehabilitation",
  //     //   icon: "equalizer",
  //     //   fullPageMenu: false,
  //     //   isMenuOpen: false,
  //     //   third_child_routes: null,
  //     //   permission: permission && permission?.Deputy_Collector_Department && permission?.Deputy_Collector_Department !== undefined ? permission && permission?.Deputy_Collector_Department && permission?.Deputy_Collector_Department?.includes("Paid_Rehabilitation") : false,

  //     // },
  //   ]
  // },

  // {
  //   menu_title: "Estate Department",
  //   path: "/app/estate-dept",
  //   icon: <i className="fa  fa-snowflake" aria-hidden="true"></i>,
  //   category: "modules",
  //   isMenuOpen: false,
  //   menu: "master",
  //   fullPageMenu: false,
  //   desc: true,
  //   content: "",
  //   permission:
  //     permission && permission.Estate_Department !== undefined
  //       ? permission && permission.Estate_Department.length > 0
  //       : false,
  //   child_routes: [
  //     {
  //       path: "/app/estate-dept/form-1",
  //       menu_title: "Total PAPs",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission: permission && permission?.Estate_Department && permission?.Estate_Department !== undefined ? permission && permission?.Estate_Department && (permission?.Estate_Department?.includes("Total_PAPs") || permission?.Estate_Department?.includes("Form_1")) : false,
  //     },
  //     {
  //       path: "/app/estate-dept/form-2",
  //       menu_title: "List Of Application",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission: permission && permission?.Estate_Department && permission?.Estate_Department !== undefined ? permission && permission?.Estate_Department && (permission?.Estate_Department?.includes("List_of_Application") || permission?.Estate_Department?.includes("Form_2")) : false,
  //     },

  //   ]
  // },
  {
    menu_title: "Legal Heir",
    path: "/app/legal-heir",
    icon: <i class="fa fa-user-circle-o" aria-hidden="true"></i>,
    category: "modules",
    isMenuOpen: false,
    menu: "master",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission:
      permission && permission.TenementForm !== undefined
        ? permission && permission.TenementForm.length > 0
        : false,
    child_routes: [
      {
        path: "/app/legal-heir/application-list",
        menu_title: "Applications",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: permission && permission?.TenementForm && permission?.TenementForm !== undefined ? permission && permission?.TenementForm && permission?.TenementForm?.includes("Application_List") : false,
      },
      {
        path: "/app/legal-heir/application-report",
        menu_title: "Application Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: permission && permission?.TenementForm && permission?.TenementForm !== undefined ? permission && permission?.TenementForm && permission?.TenementForm?.includes("Application_Report") : false,
      },
      {
        path: "/app/legal-heir/status-report",
        menu_title: "Legal Heir Status Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: permission && permission?.TenementForm && permission?.TenementForm !== undefined ? permission && permission?.TenementForm && permission?.TenementForm?.includes("Status_Report") : false,
      },

    ]
  },
  //------------------------Setting-------------------------//
  {
    menu_title: "Settings",
    path: "/app/settings",
    icon: <i className="fa  fa-empire" aria-hidden="true"></i>,
    category: "general",
    isMenuOpen: false,
    menu: "settings",
    fullPageMenu: false,
    desc: true,
    content: "",
    child_routes: null,
    permission: true
      // permission && permission.CreateScheme !== undefined
      //   ? permission && permission.CreateScheme.includes("Create_Scheme")
      //   : false,
  },

  // {
  //   menu_title: "Setting Master",
  //   path: "/app/setting",
  //   icon: <i className="fa  fa-empire" aria-hidden="true"></i>,
  //   category: "modules",
  //   isMenuOpen: false,
  //   menu: "master",
  //   fullPageMenu: false,
  //   desc: true,
  //   content: "",
  //   permission: permission && permission.Setting_Master !== undefined
  //     ? permission && permission.Setting_Master.length > 0
  //     : false,
  //   child_routes: [
  //     {
  //       path: "/app/setting/setting",
  //       menu_title: "Setting",
  //       icon: "phonelink_setup",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission: permission && permission?.Setting_Master && permission?.Setting_Master !== undefined ? permission && permission?.Setting_Master && permission?.Setting_Master?.includes("Settings_Master") : false,
  //     },
  //   ],
  // },
  // {
  //   menu_title: "Paid Rehabilitation",
  //   path: "/app/dept/paid-rehabilitation",
  //   icon: <i className="fa fa-shield" aria-hidden="true"></i>,
  //   category: "general",
  //   isMenuOpen: false,
  //   menu: "paid-rehabilitation",
  //   fullPageMenu: false,
  //   desc: true,
  //   content: "",
  //   child_routes: null,
  //   permission:
  //     permission && permission.Paid_Rehabilitation !== undefined
  //       ? permission && permission.Paid_Rehabilitation.includes("Paid_Rehabilitation")
  //       : false,
  // },
];

export default MenuItems;
